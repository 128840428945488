export default function InventoryChestIcon(
  props: React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M4.224 2.889A2.224 2.224 0 0 0 2 5.113V10h5.118L8 7h4l.882 3H18L18 12H2V16c0 .614.498 1.112 1.112 1.112h13.776c.614 0 1.112-.498 1.112-1.112V5.113a2.224 2.224 0 0 0-2.224-2.224H4.224Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1={-5.988}
          x2={8.115}
          y1={9.989}
          y2={25.856}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0FA" />
          <stop offset={0.531} stopColor="#4579F5" />
          <stop offset={1} stopColor="#9C42F5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
