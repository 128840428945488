'use client';

import { useCallback, useContext, useState } from 'react';

import AnimatedNumbers from 'components/animated-numbers';
import { Button } from 'components/ui/button';
import { DepositDialogContext } from 'components/payments/deposit-dialog-context';
import Image from 'next/image';
import coin from 'public/coin.png';
import { createClient } from 'utils/supabase/client';
import formatCoins from 'utils/format/coins';
import { shootConfettiFromBottomCorners } from 'lib/confetti';
import { toast } from 'components/ui/sonner';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';

export default function RealtimeUserBalanceButton({
  serverBalance,
  userId,
  isStreamerModeEnabled,
}: {
  serverBalance: string;
  userId: string;
  isStreamerModeEnabled: boolean;
}) {
  const [balance, setBalance] = useState<string>(serverBalance);
  const { setOpen } = useContext(DepositDialogContext);

  const createChannel = useCallback(() => {
    const supabase = createClient();

    return supabase.channel('realtime_transactions').on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'transactions',
        filter: `user_id=eq.${userId}`,
      },
      (payload) => {
        if (payload.new.type.startsWith('coins_purchased')) {
          shootConfettiFromBottomCorners();
          toast.success(
            `Thanks for your purchase! ${formatCoins(payload.new.coins)} coins have been added to your balance.`,
            { icon: `🎉` },
          );
        }

        if (payload.new.type === 'coin_drop') {
          toast.success(
            `You received ${formatCoins(payload.new.coins)} coins from the coin drop!`,
            { icon: `💰` },
          );
        }

        setBalance(formatCoins(payload.new.running_balance));
      },
    );
  }, [userId]);

  useReconnectOnTabChange(createChannel);

  return (
    <Button
      variant="frost"
      onClick={() => {
        if (!isStreamerModeEnabled) {
          setOpen(true);
        } else {
          toast.error('Disabled while streamer mode is active');
        }
      }}
      size="lg"
      className="px-3"
    >
      <Image
        src={coin}
        alt="A gold coin"
        className="mr-1 h-[1.5em] w-[1.5em]"
      />{' '}
      <AnimatedNumbers value={balance} />
    </Button>
  );
}
