import ArrowIcon from 'icons/arrow';
import Image from 'next/image';
import circleFrame from 'public/circle-frame.png';

export default function UserDropdownMenuTrigger({
  level,
  displayName,
  currentLevelProgress,
}: {
  level: number;
  displayName: string;
  currentLevelProgress: number;
}) {
  return (
    <div className="relative grid h-10 w-52 grid-cols-[auto,1fr,auto] items-center gap-2 overflow-hidden rounded-sm bg-stripes-muted px-3 py-1">
      <UserDropdownMenuLevel level={level} />

      <div className="z-10 mr-auto w-full truncate text-start text-xs">
        {displayName && <div className="truncate font-bold">{displayName}</div>}
        {level && <div className="font-normal text-gold">Level {level}</div>}
      </div>

      <ArrowIcon className="z-10 justify-self-end" />

      {currentLevelProgress !== undefined && (
        <div
          className="absolute right-0 top-0 z-0 h-full w-full origin-right bg-secondary transition-transform"
          style={{
            transform: `scaleX(${(1 - currentLevelProgress) * 100}%)`,
          }}
        ></div>
      )}
    </div>
  );
}

function UserDropdownMenuLevel({ level }: { level?: number }) {
  return (
    <div className="relative z-10 h-7 w-7">
      <Image
        src={circleFrame}
        alt="Circle Frame"
        fill
        sizes="28px"
        className="object-contain"
      />
      <span className="absolute left-1/2 top-1/2 text-center text-xs font-bold -translate-x-1/2 -translate-y-1/2">
        {level}
      </span>
    </div>
  );
}
