'use client';

import { cn } from 'utils/shadcn';
import dynamic from 'next/dynamic';
import { forwardRef } from 'react';

const TooltipProvider = dynamic(
  () => import('@radix-ui/react-tooltip').then((mod) => mod.Provider),
  {
    ssr: false,
  },
);

const Tooltip = dynamic(
  () => import('@radix-ui/react-tooltip').then((mod) => mod.Root),
  {
    ssr: false,
  },
);

const TooltipTrigger = dynamic(
  () => import('@radix-ui/react-tooltip').then((mod) => mod.Trigger),
  {
    ssr: false,
  },
);

const Content = dynamic(
  () => import('@radix-ui/react-tooltip').then((mod) => mod.Content),
  {
    ssr: false,
  },
);

const TooltipArrow = dynamic(
  () => import('@radix-ui/react-tooltip').then((mod) => mod.Arrow),
  {
    ssr: false,
  },
);

const TooltipContent = forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <Content
    ref={ref as React.Ref<HTMLDivElement>}
    sideOffset={sideOffset}
    className={cn(
      'z-50 max-w-[90vw] overflow-hidden rounded-md bg-primary px-3 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = Content.displayName;

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipArrow,
};
