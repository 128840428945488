'use client';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import CoinBundleGrid from './coin-bundle-grid';
import ArrowIcon from 'icons/arrow';
import { Button } from 'components/ui/button';
import { type DialogProps } from '@radix-ui/react-dialog';
import AffiliateCodeForm from 'components/payments/affiliate-code-form';
import { useContext, useMemo, useState } from 'react';
import Image from 'next/image';
import Typography from 'components/ui/typography';
import formatCoins from 'utils/format/coins';
import formatCurrency from 'utils/format/currency';
import Coin from 'components/coin';
import coinflow from 'public/coinflow-logo.png';
import crypto from 'public/crypto.png';
import { TooltipArrow } from 'components/ui/tooltip';
import InfoTooltip from 'components/info-tooltip';
import Link from 'next/link';
import { type Database } from 'schema.gen';
import GiftCodeForm from 'components/payments/gift-code-form';
import { DepositDialogContext } from 'components/payments/deposit-dialog-context';
import { formatDateTime } from 'utils/format/date-time';
import Script from 'next/script';
import { onCryptoChillScriptLoad } from 'lib/cryptochill';

export default function DepositDialog({
  selfExclusion,
  affiliateCodeRedemption: redeemedAffiliateCode,
  coinBundles,
  user,
  ...props
}: DialogProps & {
  affiliateCodeRedemption: {
    affiliate_code_id: string;
    id: string;
    locked_until: string;
    redeemed_at: string;
    redeemer_id: string;
    affiliate_codes: {
      depositor_bonus_percentage: number;
      code: string;
    } | null;
  } | null;
  coinBundles: Database['public']['Tables']['coin_bundles']['Row'][];
  user: {
    id: string;
    email: string;
    name: string;
  };
  selfExclusion: {
    expires_at: string;
  } | null;
}) {
  const { open, setOpen } = useContext(DepositDialogContext);

  const [selectedBundleId, setSelectedBundleId] = useState<string | null>(null);

  const selectedBundle = useMemo(() => {
    return coinBundles?.find((bundle) => bundle.id === selectedBundleId);
  }, [coinBundles, selectedBundleId]);

  const affiliateBonus = useMemo(() => {
    if (redeemedAffiliateCode === null || selectedBundle === undefined) {
      return null;
    }

    return Math.floor(
      (selectedBundle.coins *
        redeemedAffiliateCode.affiliate_codes!.depositor_bonus_percentage) /
        100,
    );
  }, [redeemedAffiliateCode, selectedBundle]);

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    props.onOpenChange?.(open);
  };

  return (
    <>
      <Script
        id="cryptochill"
        src="https://static.cryptochill.com/static/js/sdk2.js"
        onLoad={onCryptoChillScriptLoad}
      />
      <Dialog {...props} open={open} onOpenChange={onOpenChange}>
        <DialogContent className="max-w-[900px]">
          {selfExclusion !== null && (
            <div className="flex items-center justify-between rounded-lg bg-destructive p-4">
              <Typography variant="p">
                You are currently self-excluded until{' '}
                {formatDateTime(new Date(selfExclusion.expires_at))}
              </Typography>
            </div>
          )}
          <div className="grid grid-rows-[1fr,auto] gap-4">
            <Header
              showBackButton={selectedBundle !== undefined}
              onBackClick={() => setSelectedBundleId(null)}
            />
            <GiftCodeForm onSuccess={() => props.onOpenChange?.(false)} />
            {selectedBundle === undefined && (
              <CoinBundleGrid
                onSelect={setSelectedBundleId}
                coinBundles={coinBundles}
                disabled={selfExclusion !== null}
              />
            )}
            {selectedBundle !== undefined && (
              <Summary
                bundleId={selectedBundle.id}
                costInCents={selectedBundle.cost_in_cents}
                coins={selectedBundle.coins}
                affiliateBonus={affiliateBonus}
                user={user}
                closeModal={() => {
                  setSelectedBundleId(null);
                  onOpenChange(false);
                }}
              />
            )}

            <div className="mt-4 h-px w-full bg-gold" />

            <DialogFooter>
              {redeemedAffiliateCode === null && <AffiliateCodeForm />}
              {redeemedAffiliateCode !== null && (
                <Typography variant="p" affects="muted">
                  🔥 You&apos;re currently using code{' '}
                  <span className="font-bold">
                    {redeemedAffiliateCode.affiliate_codes!.code}
                  </span>{' '}
                  for a{' '}
                  <span className="text-gold-shimmer font-bold">
                    {
                      redeemedAffiliateCode.affiliate_codes!
                        .depositor_bonus_percentage
                    }
                    % deposit bonus
                  </span>{' '}
                  until{' '}
                  {formatDateTime(new Date(redeemedAffiliateCode.locked_until))}
                  !
                </Typography>
              )}
            </DialogFooter>

            <DialogDescription className="sr-only">
              Refill your coin balance with a debit/credit card or
              cryptocurrency.
            </DialogDescription>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Header({
  showBackButton,
  onBackClick,
}: {
  showBackButton?: boolean;
  onBackClick?: () => void;
}) {
  return (
    <DialogHeader>
      <div className="grid w-full grid-cols-[1fr,auto,1fr] items-center gap-4">
        {showBackButton ? (
          <button onClick={onBackClick}>
            <ArrowIcon className="text-gold rotate-90 scale-[200%]" />
          </button>
        ) : (
          <span></span>
        )}
        <DialogTitle>Coin Refill</DialogTitle>
      </div>
    </DialogHeader>
  );
}

function Summary({
  bundleId,
  costInCents,
  coins,
  affiliateBonus,
  user,
  closeModal,
}: {
  bundleId: string;
  costInCents: number;
  coins: number;
  affiliateBonus: number | null;
  user: {
    id: string;
    email: string;
    name: string;
  };
  closeModal: () => void;
}) {
  return (
    <>
      <div className="flex flex-col gap-y-4">
        <Typography variant="p" affects="large">
          Summary
        </Typography>

        <div className="flex flex-col gap-y-2">
          <div className="bg-frost flex items-center justify-between rounded-lg p-4">
            <Typography variant="p">Price</Typography>

            <Typography variant="p">{formatCurrency(costInCents)}</Typography>
          </div>

          <div className="bg-frost flex items-center justify-between rounded-lg p-4">
            <Typography variant="p">Coins</Typography>

            <Typography variant="p" className="bg-frost rounded-lg px-2 py-1">
              <Coin className="mr-2 inline" sizing="sm" />
              {formatCoins(coins)}
            </Typography>
          </div>
          {affiliateBonus !== null && (
            <div className="bg-frost flex items-center justify-between rounded-lg p-4">
              <Typography variant="p" className="text-gold-shimmer font-bold">
                + Affiliate Bonus
              </Typography>

              <Typography variant="p" className="bg-frost rounded-lg px-2 py-1">
                <Coin className="mr-2 inline" sizing="sm" />
                {formatCoins(affiliateBonus)}
              </Typography>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
          <div className="bg-frost flex items-center justify-between rounded-lg p-4">
            <Typography variant="p" affects="large">
              Debit/Credit Card
            </Typography>

            <Link
              href={`/deposit/coinflow?bundle=${bundleId}`}
              onClick={closeModal}
            >
              <Button size="sm">
                <Image
                  src={coinflow}
                  alt="Coinflow"
                  className="h-full w-min p-2"
                />
              </Button>
            </Link>
          </div>

          <div className="bg-frost flex items-center justify-between rounded-lg p-4">
            <span className="flex items-center gap-2">
              <Typography variant="p" affects="large">
                Cryptocurrency
              </Typography>

              <CryptoTooltip />
            </span>
            <Button
              size="sm"
              variant="ghost"
              className="cryptochill-button"
              onClick={() => {
                closeModal();
                window.CryptoChill.open({
                  amount: costInCents / 100,
                  currency: 'USD',
                  onSuccess: () => {
                    window.CryptoChill.destroy();
                  },
                  product: `Pullbox Coins`,
                  showPaymentURL: true,
                  passthrough: JSON.stringify({
                    userId: user.id,
                    email: user.email,
                    name: user.name,
                    currencyBundleId: bundleId,
                  }),
                });
              }}
            >
              <Image
                src={crypto}
                alt="Various cryptocurrencies"
                className="h-full w-min"
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

function CryptoTooltip() {
  return (
    <InfoTooltip>
      <Typography variant="p">
        Pullbox.gg accepts cryptocurrency as a payment method. We accept Bitcoin
        (BTC), Ethereum (ETH), or Litecoin (LTC) for coin purchases.
      </Typography>

      <Typography variant="p">
        If you are new to crypto and would like to acquire it, explore popular
        exchanges like Coinbase, Gemini, or Crypto.com, where you can purchase
        and transfer all different types of cryptocurrency. You can then use
        these exchanges to create a crypto wallet, and deposit that
        cryptocurrency on Pullbox.gg!
      </Typography>

      <TooltipArrow width={16} height={8} className="fill-gold" />
    </InfoTooltip>
  );
}
