import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    NODE_ENV: z
      .enum(["development", "test", "production"])
      .default("development"),
    CRYPTOCHILL_CALLBACK_TOKEN: z.string(),
    COINFLOW_VALIDATION_KEY: z.string(),
    DATABASE_URL: z.string().url(),
    DISCORD_PULLBOX_GUILD_ID: z.string(),
    GSAP_NPM_TOKEN: z.string(),
    HCAPTCHA_ACCOUNT_LEVEL_SECRET: z.string(),
    INTERCOM_IDENTITY_VERIFICATION_SECRET: z.string(),
    MAINTENANCE_BYPASS_PASSWORD: z.string(),
    MAINTENANCE_MODE: z.enum(['true', 'false']).transform(value => value === 'true'),
    PULLBOX_FULFILLMENT_API_KEY: z.string(),
    QSTASH_CURRENT_SIGNING_KEY: z.string(),
    QSTASH_NEXT_SIGNING_KEY: z.string(),
    QSTASH_TOKEN: z.string(),
    QSTASH_ENABLED: z.enum(['true', 'false']).transform(value => value === 'true'),
    SERVER_SEED_ENCRYPTION_KEY: z.string(),
    SERVER_SEED_ENCRYPTION_INITIALIZATION_VECTOR: z.string(),
    SUPABASE_AUTH_DISCORD_CLIENT_ID: z.string(),
    SUPABASE_AUTH_DISCORD_SECRET: z.string(),
    SUPABASE_AUTH_GOOGLE_CLIENT_ID: z.string(),
    SUPABASE_AUTH_GOOGLE_SECRET: z.string(),
    SUPABASE_AUTH_SMS_TWILIO_AUTH_TOKEN: z.string(),
    SUPABASE_AUTH_TWITCH_CLIENT_ID: z.string(),
    SUPABASE_AUTH_TWITCH_SECRET: z.string(),
    SUPABASE_SERVICE_ROLE_KEY: z.string(),
    TWILIO_ACCOUNT_SID: z.string(),
    TWILIO_MESSAGE_SERVICE_SID: z.string(),
    UPSTASH_REDIS_REST_TOKEN: z.string(),
    UPSTASH_REDIS_REST_URL: z.string(),
    VERCEL_ENV: z.enum(['production', 'preview', 'development']).default('development'),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
  */
  client: {
    NEXT_PUBLIC_BATTLE_MAX_BOX_COUNT: z.coerce.number(),
    NEXT_PUBLIC_CAPTCHA_ENABLED: z.enum(['true', 'false']).transform(value => value === 'true'),
    NEXT_PUBLIC_COINFLOW_ENV: z.enum(['local', 'sandbox', 'staging', 'prod',]).default('local'),
    NEXT_PUBLIC_COINFLOW_MERCHANT_ID: z.string(),
    NEXT_PUBLIC_CRYPTOCHILL_ACCOUNT_ID: z.string(),
    NEXT_PUBLIC_CRYPTOCHILL_PROFILE_ID: z.string(),
    NEXT_PUBLIC_DEV_ONLY_NGROK_URL: z.string().optional(),
    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID: z.string(),
    NEXT_PUBLIC_INTERCOM_APP_ID: z.string(),
    NEXT_PUBLIC_HCAPTCHA_SITE_KEY: z.string(),
    NEXT_PUBLIC_MAX_WITHDRAWAL_ITEMS: z.coerce.number(),
    NEXT_PUBLIC_WEB_APP_BASE_URL: z.string().url(),
    NEXT_PUBLIC_SUPABASE_URL: z.string().url(),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    COINFLOW_VALIDATION_KEY: process.env.COINFLOW_VALIDATION_KEY,
    CRYPTOCHILL_CALLBACK_TOKEN: process.env.CRYPTOCHILL_CALLBACK_TOKEN,
    DATABASE_URL: process.env.DATABASE_URL,
    DISCORD_PULLBOX_GUILD_ID: process.env.DISCORD_PULLBOX_GUILD_ID,
    GSAP_NPM_TOKEN: process.env.GSAP_NPM_TOKEN,
    HCAPTCHA_ACCOUNT_LEVEL_SECRET: process.env.HCAPTCHA_ACCOUNT_LEVEL_SECRET,
    INTERCOM_IDENTITY_VERIFICATION_SECRET: process.env.INTERCOM_IDENTITY_VERIFICATION_SECRET,
    MAINTENANCE_BYPASS_PASSWORD: process.env.MAINTENANCE_BYPASS_PASSWORD,
    MAINTENANCE_MODE: process.env.MAINTENANCE_MODE,
    NEXT_PUBLIC_BATTLE_MAX_BOX_COUNT: process.env.NEXT_PUBLIC_BATTLE_MAX_BOX_COUNT,
    NEXT_PUBLIC_CAPTCHA_ENABLED: process.env.NEXT_PUBLIC_CAPTCHA_ENABLED,
    NEXT_PUBLIC_COINFLOW_ENV: process.env.NEXT_PUBLIC_COINFLOW_ENV,
    NEXT_PUBLIC_COINFLOW_MERCHANT_ID: process.env.NEXT_PUBLIC_COINFLOW_MERCHANT_ID,
    NEXT_PUBLIC_CRYPTOCHILL_ACCOUNT_ID: process.env.NEXT_PUBLIC_CRYPTOCHILL_ACCOUNT_ID,
    NEXT_PUBLIC_CRYPTOCHILL_PROFILE_ID: process.env.NEXT_PUBLIC_CRYPTOCHILL_PROFILE_ID,
    NEXT_PUBLIC_DEV_ONLY_NGROK_URL: process.env.NEXT_PUBLIC_DEV_ONLY_NGROK_URL,
    NEXT_PUBLIC_HCAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY,
    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID: process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID,
    NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    NEXT_PUBLIC_MAX_WITHDRAWAL_ITEMS: process.env.NEXT_PUBLIC_MAX_WITHDRAWAL_ITEMS,
    NEXT_PUBLIC_WEB_APP_BASE_URL: process.env.NEXT_PUBLIC_WEB_APP_BASE_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NODE_ENV: process.env.NODE_ENV,
    PULLBOX_FULFILLMENT_API_KEY: process.env.PULLBOX_FULFILLMENT_API_KEY,
    QSTASH_CURRENT_SIGNING_KEY: process.env.QSTASH_CURRENT_SIGNING_KEY,
    QSTASH_NEXT_SIGNING_KEY: process.env.QSTASH_NEXT_SIGNING_KEY,
    QSTASH_TOKEN: process.env.QSTASH_TOKEN,
    QSTASH_ENABLED: process.env.QSTASH_ENABLED,
    SERVER_SEED_ENCRYPTION_INITIALIZATION_VECTOR: process.env.SERVER_SEED_ENCRYPTION_INITIALIZATION_VECTOR,
    SERVER_SEED_ENCRYPTION_KEY: process.env.SERVER_SEED_ENCRYPTION_KEY,
    SUPABASE_AUTH_DISCORD_CLIENT_ID: process.env.SUPABASE_AUTH_DISCORD_CLIENT_ID,
    SUPABASE_AUTH_DISCORD_SECRET: process.env.SUPABASE_AUTH_DISCORD_SECRET,
    SUPABASE_AUTH_GOOGLE_CLIENT_ID: process.env.SUPABASE_AUTH_GOOGLE_CLIENT_ID,
    SUPABASE_AUTH_GOOGLE_SECRET: process.env.SUPABASE_AUTH_GOOGLE_SECRET,
    SUPABASE_AUTH_SMS_TWILIO_AUTH_TOKEN: process.env.SUPABASE_AUTH_SMS_TWILIO_AUTH_TOKEN,
    SUPABASE_AUTH_TWITCH_CLIENT_ID: process.env.SUPABASE_AUTH_TWITCH_CLIENT_ID,
    SUPABASE_AUTH_TWITCH_SECRET: process.env.SUPABASE_AUTH_TWITCH_SECRET,
    SUPABASE_SERVICE_ROLE_KEY: process.env.SUPABASE_SERVICE_ROLE_KEY,
    TWILIO_ACCOUNT_SID: process.env.TWILIO_ACCOUNT_SID,
    TWILIO_MESSAGE_SERVICE_SID: process.env.TWILIO_MESSAGE_SERVICE_SID,
    UPSTASH_REDIS_REST_TOKEN: process.env.UPSTASH_REDIS_REST_TOKEN,
    UPSTASH_REDIS_REST_URL: process.env.UPSTASH_REDIS_REST_URL,
    VERCEL_ENV: process.env.VERCEL_ENV,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  /**
   * Makes it so that empty strings are treated as undefined. `SOME_VAR: z.string()` and
   * `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true,
});
