// components/error-boundary.tsx
'use client';

import { ErrorBoundary as HighlightErrorBoundary } from '@highlight-run/next/client';

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <HighlightErrorBoundary
      showDialog
      dialogOptions={{ hideHighlightBranding: true }}
    >
      {children}
    </HighlightErrorBoundary>
  );
}
