'use client';

import { toggleChatOpen } from 'actions/chat';
import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

export const ChatOpenContext = createContext<{
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}>({
  isOpen: false,
  setOpen: () => {},
});

export default function ChatOpenProvider({
  isDefaultOpen,
  children,
}: {
  isDefaultOpen: boolean;
  children: ReactNode;
}) {
  const [isOpen, setOpen] = useState(isDefaultOpen);
  const [previousIsOpen, setPreviousIsOpen] = useState(isDefaultOpen);

  useEffect(() => {
    if (previousIsOpen !== isOpen) {
      setPreviousIsOpen(isOpen);

      void toggleChatOpen();
    }
  }, [isOpen, previousIsOpen]);

  return (
    <ChatOpenContext.Provider value={{ isOpen, setOpen }}>
      {children}
    </ChatOpenContext.Provider>
  );
}
export const useChatOpen = () => {
  return useContext(ChatOpenContext);
};
