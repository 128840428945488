'use client';

import { type ReactNode, createContext, useState } from 'react';

export const DepositDialogContext = createContext<{
  open: boolean;
  setOpen: (open: boolean) => void;
}>({
  open: false,
  setOpen: () => {},
});

export default function DepositDialogProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [open, setOpen] = useState(false);

  return (
    <DepositDialogContext.Provider
      value={{
        open,
        setOpen,
      }}
    >
      {children}
    </DepositDialogContext.Provider>
  );
}
