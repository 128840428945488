import ArrowIcon from 'icons/arrow';
import DiscordIcon from 'icons/discord';
import Divider from 'components/ui/divider';
import DoubleDiamondIcon from 'icons/double-diamond';
import FacebookIcon from 'icons/facebook';
import FullLogo from 'components/full-logo';
import Image from 'next/image';
import InstagramIcon from 'icons/instagram';
import Link from 'next/link';
import type { LinkProps } from 'next/link';
import type { ReactNode } from 'react';
import TikTokIcon from 'icons/tik-tok';
import Typography from 'components/ui/typography';
import circleFrame from 'public/circle-frame.png';

export default function Footer() {
  return (
    <div className="mt-12 grid grid-cols-1 gap-6 pb-12 md:grid-cols-12">
      <span className="md:col-span-12">
        <Divider variant="section" />
      </span>

      {/* Information */}
      <section className="flex w-full flex-col gap-y-1 md:order-2 md:col-span-3">
        <Header>Information</Header>

        <FooterLink href="/how-it-works">How It Works</FooterLink>
        <FooterLink href="/faq">FAQ</FooterLink>
        <FooterLink href="/provably-fair">Provably Fair</FooterLink>
        <FooterLink href="/write-to-us">Write To Us</FooterLink>
      </section>

      {/* Legal */}
      <section className="flex w-full flex-col gap-y-1 md:order-3 md:col-span-3">
        <Header>Legal</Header>

        <FooterLink href="/privacy">Privacy Policy</FooterLink>
        <FooterLink href="/cookies">Cookie Policy</FooterLink>
        <FooterLink href="/terms">Terms of Use</FooterLink>
        <FooterLink href="/consent-preferences">Consent Preferences</FooterLink>
      </section>

      {/* Copyright */}
      <section className="md:order-1 md:col-span-6">
        <div className="flex h-full gap-x-4">
          <div className="space-y-4">
            <div className="mt-6 flex flex-col items-start gap-y-4 ">
              <FullLogo className="h-4 md:h-auto" />

              <Typography
                variant="p"
                affects="xs"
                className="text-muted-foreground"
              >
                © {new Date().getFullYear()} CCG Labs, LLC
              </Typography>

              <Typography
                variant="p"
                affects="xs"
                className="text-muted-foreground"
              >
                All product and company names are trademarks or registered
                trademarks of their respective holders. Use of them does not
                imply any affiliation with or endorsement by them.
              </Typography>

              <Typography
                variant="p"
                affects="xs"
                className="text-muted-foreground"
              >
                Follow us
              </Typography>
            </div>

            {/* Social media */}
            <div className="mx-auto grid grid-cols-[min-content,min-content,min-content,min-content] gap-8">
              <Link
                href="https://discord.gg/UZ6NndQfua"
                className="relative h-12 w-12"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={circleFrame}
                  alt="Circle Frame"
                  fill
                  sizes="28px"
                  className="object-contain"
                />

                <DiscordIcon className="absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2" />
              </Link>
              <Link
                href="https://instagram.com/pullbox.gg"
                className="relative h-12 w-12"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={circleFrame}
                  alt="Circle Frame"
                  fill
                  sizes="28px"
                  className="object-contain"
                />

                <InstagramIcon className="absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2" />
              </Link>
              <Link
                href="https://www.facebook.com/people/Pullboxgg/61553808437625/"
                className="relative h-12 w-12"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={circleFrame}
                  alt="Circle Frame"
                  fill
                  sizes="28px"
                  className="object-contain"
                />

                <FacebookIcon className="absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2" />
              </Link>
              <Link
                href="https://www.tiktok.com/@pullbox.gg"
                className="relative h-12 w-12"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={circleFrame}
                  alt="Circle Frame"
                  fill
                  sizes="28px"
                  className="object-contain"
                />

                <TikTokIcon className="absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2" />
              </Link>
            </div>
          </div>
          <div className="hidden h-full grid-rows-[auto,1fr,auto] items-center justify-center gap-2 md:grid">
            <DoubleDiamondIcon className="text-gold" />
            <div className="h-full w-px shrink self-center justify-self-center bg-gold" />
            <DoubleDiamondIcon className="text-gold" />
          </div>
        </div>
      </section>
    </div>
  );
}

function Header({ children }: { children: ReactNode }) {
  return (
    <Typography
      variant="p"
      affects="default"
      className="mb-2 text-base font-normal uppercase tracking-tight text-rich-gold"
    >
      {children}
    </Typography>
  );
}

function FooterLink({
  children,
  ...props
}: LinkProps & { children: ReactNode }) {
  return (
    <div className="group relative flex w-full items-center justify-between overflow-hidden rounded-md">
      <div className="absolute inset-0 bg-gradient-to-r from-[#9D9D9D]/20 to-transparent opacity-100 transition-opacity group-hover:opacity-0"></div>
      <div className="absolute inset-0 bg-gradient-to-r from-rich-gold to-transparent opacity-0 transition-opacity group-hover:opacity-100"></div>
      <Link {...props} className="relative block w-full px-2 py-2">
        <Typography
          variant="span"
          className="font-normal tracking-tight text-muted-foreground transition-colors group-hover:text-white"
        >
          {children}
        </Typography>
      </Link>
      <span className="relative mr-2 flex size-4 items-center justify-center">
        <ArrowIcon className="absolute left-[11px] top-[7px] w-4 text-muted-foreground transition-colors -translate-x-1/2 -translate-y-1/2 -rotate-90 group-hover:text-white" />
        <div className="absolute left-0 top-0 -z-10 size-4 origin-center overflow-hidden bg-muted rotate-45">
          <div className="absolute left-0 top-0 h-full w-full bg-muted" />
          <div className="absolute left-0 top-0 h-full w-full bg-orange-gradient opacity-0 transition-opacity group-hover:opacity-100" />
        </div>
      </span>
    </div>
  );
}
