import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/app/error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/identify-user.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/layout-controller.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/maintenance.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/auth/auth-dialog-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/chat-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/chat-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/chat-open-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/live-hits-open-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/online-count.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/realtime-chat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/realtime-coin-drop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/coinflow/wrapped-coinflow-purchase-protection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/header/login.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header/realtime-user-balance-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header/realtime-user-dropdown-menu-trigger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header/realtime-user-inventory-button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/header/sign-out.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/intercom/wrapped-intercom-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LiveHitsSkeleton","default"] */ "/vercel/path0/components/live-hits/realtime-live-hits.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/payments/awaiting-crypto-confirmations-toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/payments/deposit-dialog-context.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/payments/deposit-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogContent","DialogHeader","DialogTitle"] */ "/vercel/path0/components/ui/dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Drawer","DrawerTrigger","DrawerContent","DrawerHeader","DrawerTitle","DrawerDescription","DrawerClose"] */ "/vercel/path0/components/ui/drawer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HighlightInit"] */ "/vercel/path0/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"utils/fonts.ts\",\"import\":\"Rubik\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"utils/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/gotham-pro/gothampro_light.ttf\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_lightitalic.ttf\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"../fonts/gotham-pro/gothampro.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_italic.ttf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../fonts/gotham-pro/gothampro_medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_mediumitalic.ttf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../fonts/gotham-pro/gothampro_bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_bolditalic.ttf\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../fonts/gotham-pro/gothampro_black.ttf\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_blackitalic.ttf\",\"weight\":\"900\",\"style\":\"italic\"}],\"variable\":\"--font-gotham\"}],\"variableName\":\"fontGotham\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/circle-frame.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
