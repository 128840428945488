'use client';

import { useCallback, useState } from 'react';

import AnimatedNumbers from 'components/animated-numbers';
import { Button } from 'components/ui/button';
import InventoryChestIcon from 'icons/inventory-chest';
import { createClient } from 'utils/supabase/client';
import dynamic from 'next/dynamic';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';

const QuickInventoryDialog = dynamic(() => import('./quick-inventory-dialog'));

export default function RealtimeUserInventoryButton({
  serverInventoryCount,
  serverInventoryValue,
  userId,
}: {
  serverInventoryCount: number;
  serverInventoryValue: number;
  userId: string;
}) {
  const [count, setCount] = useState(serverInventoryCount);
  const [isOpen, setIsOpen] = useState(false);
  const createChannel = useCallback(() => {
    const supabase = createClient();
    return supabase.channel('realtime_inventory_count').on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'realtime_inventory_count',
        filter: `user_id=eq.${userId}`,
      },
      (payload) => {
        if (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE') {
          setCount(payload.new.count);
        }
      },
    );
  }, [userId]);

  useReconnectOnTabChange(createChannel);

  return (
    <>
      <Button
        variant="frost"
        size="lg"
        className="px-3"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <span className="flex items-center gap-1 text-xs">
          <InventoryChestIcon />
          <AnimatedNumbers value={count.toString()}></AnimatedNumbers>
        </span>
      </Button>
      <QuickInventoryDialog
        userId={userId}
        open={isOpen}
        onOpenChange={setIsOpen}
        totalItemCount={count}
        totalItemValue={serverInventoryValue}
      ></QuickInventoryDialog>
    </>
  );
}
