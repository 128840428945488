'use client';

import Intercom from '@intercom/messenger-js-sdk';
import type { User } from '@supabase/supabase-js';
import { env } from 'env';

export default function WrappedIntercomClient({
  user,
  identityVerificationHash,
}: {
  user: User;
  identityVerificationHash: string;
}) {
  Intercom({
    app_id: env.NEXT_PUBLIC_INTERCOM_APP_ID,
    user_id: user.id,
    email: user.email,
    created_at: Math.floor(new Date(user.created_at).getTime() / 1000),
    user_hash: identityVerificationHash,
    alignment: 'left',
    custom_launcher_selector: '.custom-intercom-launcher',
    hide_default_launcher: true,
    horizontal_padding: 48,
  });

  return null;
}
