'use client';

import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Badge } from 'components/ui/badge';
import { ChatContext } from './chat-context';
import Image from 'next/image';
import Typography from 'components/ui/typography';

export default function RealtimeChat() {
  const { messages } = useContext(ChatContext);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

  const scrollToBottom = useCallback((behavior: ScrollBehavior = 'smooth') => {
    if (scrollRef.current !== null) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior,
      });
    }
  }, []);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current !== null && isScrolledToBottom) {
      scrollToBottom();
    }
  }, [isScrolledToBottom, messages, scrollToBottom]);

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLDivElement;
    const atBottom = target.scrollTop > -50;

    setIsScrolledToBottom(atBottom);
  }, []);

  return (
    <div
      className="no-scrollbar flex w-full max-w-full flex-col-reverse gap-y-5 overflow-x-hidden overflow-y-scroll border-b border-b-gray-500 p-2 pb-5"
      ref={scrollRef}
      onScroll={handleScroll}
    >
      {messages.map((message) => (
        <div key={message.hash} className="grid grid-cols-[auto,1fr] gap-x-2">
          <Avatar className="size-8">
            <AvatarImage src={message.avatar} alt={message.username} asChild>
              <Image
                src={message.avatar}
                alt={message.username}
                fill
                sizes="5vw"
                priority
              />
            </AvatarImage>
            <AvatarFallback></AvatarFallback>
          </Avatar>

          <div className="flex flex-col gap-y-1">
            <span className="flex flex-wrap items-center gap-x-1">
              <Typography variant="p" affects="small">
                <span>{message.username}</span>
              </Typography>
              {message.is_new ? (
                <Badge
                  variant="success"
                  className="rounded-xs bg-success/50 text-xs"
                >
                  New
                </Badge>
              ) : (
                <Badge
                  variant="secondary"
                  className="rounded-xs bg-rich-gold/50 text-white"
                >
                  {message.user_level}
                </Badge>
              )}
            </span>
            <Typography
              variant="p"
              affects="small"
              className="w-full max-w-full break-words font-normal text-muted-foreground"
              style={{
                overflowWrap: 'anywhere',
              }}
            >
              {message.text}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
}
