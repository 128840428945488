'use client';

import { useCallback, useState } from 'react';

import UserDropdownMenuTrigger from './user-dropdown-menu-trigger';
import { createClient } from 'utils/supabase/client';
import { getCurrentLevelProgress } from 'utils/xp';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';

export default function RealtimeUserDropdownMenuTrigger({
  userId,
  serverLevel,
  serverCurrentLevelProgress,
  displayName,
}: {
  userId: string;
  serverLevel: number;
  serverCurrentLevelProgress: number;
  displayName: string;
}) {
  const [level, setLevel] = useState(serverLevel);
  const [currentLevelProgress, setCurrentLevelProgress] = useState(
    serverCurrentLevelProgress,
  );

  const createChannel = useCallback(() => {
    const supabase = createClient();
    return supabase.channel('realtime_user_level').on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'user_levels',
        filter: `id=eq.${userId}`,
      },
      (payload) => {
        if (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE') {
          setLevel(payload.new.level);

          setCurrentLevelProgress(getCurrentLevelProgress(payload.new.xp));
        }
      },
    );
  }, [userId]);
  useReconnectOnTabChange(createChannel);

  return (
    <UserDropdownMenuTrigger
      level={level}
      displayName={displayName}
      currentLevelProgress={currentLevelProgress}
    />
  );
}
