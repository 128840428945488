'use client';

import { useCallback, useEffect, useState } from 'react';

import type { Database } from 'schema.gen';
import { H } from '@highlight-run/next/client';
import type { Session } from '@supabase/supabase-js';

type Profile = Database['public']['Tables']['user_profiles']['Row'];

export default function IdentifyUser() {
  const [session, setSession] = useState<Session | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);

  const fetchSession = useCallback(async () => {
    const { createClient } = await import('utils/supabase/client');
    const supabase = createClient();
    const { data: sessionData } = await supabase.auth.getSession();
    const { data: profileData } = await supabase
      .from('user_profiles')
      .select('*')
      .filter('id', 'eq', sessionData.session?.user.id)
      .maybeSingle();

    setSession(sessionData.session);
    setProfile(profileData);
  }, []);

  useEffect(() => {
    void fetchSession();
  }, [fetchSession]);

  useEffect(() => {
    if (session?.user.id && profile?.username) {
      H.identify(session.user.id, {
        id: session.user.id,
        email: session.user.email!,
        username: profile.username,
        avatar: profile.avatar,
        signed_up: profile.created_at,
      });
    }
  }, [
    session?.user.id,
    session?.user.email,
    profile?.username,
    profile?.avatar,
    profile?.created_at,
  ]);

  return null;
}
