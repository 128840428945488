'use client';

import { useEffect, useState } from 'react';

import { Button } from 'components/ui/button';
import FullLogo from 'components/full-logo';
import { Input } from 'components/ui/input';
import Typography from 'components/ui/typography';
import { bypassMaintenance } from 'actions/maintenance';

const konamiCode = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'KeyB',
  'KeyA',
];

export default function Maintenance() {
  const [codePosition, setCodePosition] = useState(0);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === konamiCode[codePosition]) {
        setCodePosition((prev) => prev + 1);
        if (codePosition + 1 === konamiCode.length) {
          setShowPasswordPrompt(true);
        }
      } else {
        setCodePosition(0);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [codePosition]);

  const handlePasswordSubmit = async () => {
    const response = await bypassMaintenance(password);

    if (response.success) {
      window.location.reload();
    } else {
      setError('Incorrect password');
    }
  };

  return (
    <div className="grid h-screen grid-flow-row grid-rows-[1fr_auto_1fr] items-center justify-center p-6">
      <div className="mx-auto self-start">
        <FullLogo />
      </div>
      <div className="bg-frost rounded-xl p-6 md:p-8">
        <Typography variant="p" affects="lead">
          Hang tight, we&apos;re in
        </Typography>
        <Typography variant="h1" affects="4xl" className="text-gold-shimmer ">
          Maintenance Mode
        </Typography>
      </div>
      {showPasswordPrompt && (
        <div className="grid grid-cols-[1fr_auto] gap-2 self-end">
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <Button onClick={handlePasswordSubmit} size="sm">
            Submit
          </Button>
          {error && (
            <Typography
              className="text-destructive"
              variant="span"
              affects="xs"
            >
              {error}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
