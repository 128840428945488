'use client';

import { Button } from 'components/ui/button';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const AuthDialog = dynamic(() => import('components/auth/auth-dialog'));

export default function Login() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant="ghost" onClick={() => setOpen(true)}>
        Log In
      </Button>

      <AuthDialog open={open} onOpenChange={(open) => setOpen(open)} />
    </>
  );
}
