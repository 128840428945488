import React from 'react';

interface ChatIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function ChatIcon({ size = 34, ...props }: ChatIconProps) {
  const scale = size / 34;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 34 34"
      {...props}
    >
      <path
        fill="#fff"
        d={`M${25.808 * scale} ${22.311 * scale}a${6.874 * scale} ${
          6.874 * scale
        } 0 0 0 ${-5.234 * scale} ${-10.072 * scale}A${6.874 * scale} ${
          6.874 * scale
        } 0 1 0 ${8.19 * scale} ${18.186 * scale}l${-0.532 * scale} ${
          1.856 * scale
        }a${1.195 * scale} ${1.195 * scale} 0 0 0 ${1.486 * scale} ${
          1.487 * scale
        }l${1.857 * scale} ${-0.533 * scale}a${6.943 * scale} ${
          6.943 * scale
        } 0 0 0 ${2.423 * scale} ${0.765 * scale}a${6.875 * scale} ${
          6.875 * scale
        } 0 0 0 ${9.573 * scale} ${3.36 * scale}l${1.857 * scale} ${
          0.533 * scale
        }a${1.194 * scale} ${1.194 * scale} 0 0 0 ${1.486 * scale} ${
          -1.487 * scale
        }l${-0.532 * scale} ${-1.856 * scale}Zm${-1.324 * scale} ${
          -0.438 * scale
        }a${0.655 * scale} ${0.655 * scale} 0 0 0 ${-0.077 * scale} ${
          0.532 * scale
        }l${0.533 * scale} ${1.848 * scale} ${-1.848 * scale} ${
          -0.533 * scale
        }a${0.654 * scale} ${0.654 * scale} 0 0 0 ${-0.533 * scale} ${
          0.078 * scale
        }a${5.517 * scale} ${5.517 * scale} 0 0 1 ${-7.597 * scale} ${
          -2.02 * scale
        }a${6.892 * scale} ${6.892 * scale} 0 0 0 ${6.162 * scale} ${
          -6.84 * scale
        }a${7.183 * scale} ${7.183 * scale} 0 0 0 ${-0.112 * scale} ${
          -1.23 * scale
        }a${5.5 * scale} ${5.5 * scale} 0 0 1 ${4.237 * scale} ${
          5.354 * scale
        }a${5.569 * scale} ${5.569 * scale} 0 0 1 ${-0.765 * scale} ${
          2.81 * scale
        }Z`}
      />
      {/* <defs>
        <linearGradient
          id="a"
          x1={16.999 * scale}
          x2={16.999 * scale}
          y1={0}
          y2={34 * scale}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs> */}
    </svg>
  );
}
