'use client';

import { Button, type ButtonProps } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { useCallback, useRef } from 'react';

import { EMPTY_FORM_STATE } from 'utils/forms';
import { Input } from 'components/ui/input';
import redeemGiftCode from 'actions/gift-codes';
import { redeemGiftCodeSchema } from 'actions/gift-codes/schema';
import { useForm } from 'react-hook-form';
import { useFormStatus } from 'react-dom';
import { zodResolver } from '@hookform/resolvers/zod';

export default function GiftCodeForm({
  onSuccess,
}: {
  onSuccess?: () => void;
}) {
  const form = useForm({
    resolver: zodResolver(redeemGiftCodeSchema),
    defaultValues: { code: '' },
  });

  const formRef = useRef<HTMLFormElement>(null);
  const onSubmit = useCallback(async () => {
    const formState = await redeemGiftCode(
      EMPTY_FORM_STATE,
      new FormData(formRef.current!),
    );

    if (formState.status === 'SUCCESS') {
      onSuccess?.();
    } else if (formState.status === 'ERROR') {
      form.setError('code', { type: 'manual', message: formState.message });
    }
  }, [form, onSuccess]);

  return (
    <Form {...form}>
      <form
        ref={formRef}
        onSubmit={form.handleSubmit(onSubmit)}
        className="grid grid-cols-[1fr,auto] gap-2"
      >
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormControl onChange={() => form.clearErrors('code')}>
                <Input placeholder="Have a gift code?" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <SubmitButton variant="secondary-action">Redeem</SubmitButton>
      </form>
    </Form>
  );
  return;
}

function SubmitButton({ children, ...props }: ButtonProps) {
  const { pending } = useFormStatus();

  return (
    <Button {...props} type="submit" isProcessing={pending} disabled={pending}>
      {children}
    </Button>
  );
}
