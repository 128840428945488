'use client';

import { toggleLiveHitsOpen } from 'actions/live-hits';
import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

export const LiveHitsOpenContext = createContext<{
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}>({
  isOpen: false,
  setOpen: () => {},
});

export default function LiveHitsOpenProvider({
  isDefaultOpen,
  children,
}: {
  isDefaultOpen: boolean;
  children: ReactNode;
}) {
  const [isOpen, setOpen] = useState(isDefaultOpen);
  const [previousIsOpen, setPreviousIsOpen] = useState(isDefaultOpen);

  useEffect(() => {
    if (previousIsOpen !== isOpen) {
      setPreviousIsOpen(isOpen);

      void toggleLiveHitsOpen();
    }
  }, [isOpen, previousIsOpen]);

  return (
    <LiveHitsOpenContext.Provider value={{ isOpen, setOpen }}>
      {children}
    </LiveHitsOpenContext.Provider>
  );
}
export const useLiveHitsOpen = () => {
  return useContext(LiveHitsOpenContext);
};
