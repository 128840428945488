'use client';

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip';

import InfoIcon from 'icons/info';
import { useState } from 'react';

export default function InfoTooltip({
  children,
}: {
  children: React.ReactNode;
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <TooltipProvider>
      <Tooltip
        delayDuration={0}
        open={tooltipOpen}
        onOpenChange={(open) => setTooltipOpen(open)}
      >
        <TooltipTrigger asChild>
          <button
            className="bg-frost group rounded-sm p-1 transition-colors hover:bg-gold"
            onClick={() => setTooltipOpen(true)}
          >
            <InfoIcon
              width={12}
              height={12}
              className="text-gold transition-colors group-hover:text-white"
            />
          </button>
        </TooltipTrigger>
        <TooltipContent
          className="w-96 space-y-4 bg-gold p-4 text-white"
          sideOffset={8}
        >
          {children}

          <TooltipArrow width={16} height={8} className="fill-gold" />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
