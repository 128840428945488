'use client';

import React, { Suspense } from 'react';

import AnimatedNumberWithSpring from './animated-number-with-spring';

const DURATION_DELTA = 50;
export default function AnimatedNumbers({ value }: { value: string }) {
  const valueParts = value.split('');
  const numberCount = valueParts.filter((part) => !isNaN(Number(part))).length;
  const maxDuration = numberCount * DURATION_DELTA;

  return (
    <div className="flex overflow-hidden">
      {valueParts.map((part, i) => {
        if (isNaN(Number(part))) {
          return <span key={i}>{part}</span>;
        }

        return (
          <Suspense fallback={<span key={i}>{part}</span>} key={i}>
            <AnimatedNumberWithSpring
              value={Number(part)}
              duration={
                maxDuration -
                i * (maxDuration / numberCount) +
                DURATION_DELTA * 10
              }
            />
          </Suspense>
        );
      })}
    </div>
  );
}
