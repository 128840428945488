'use client';

import './layout-controller.css';

import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';

import { CSSTransition } from 'react-transition-group';
import ChatIcon from 'icons/chat';
import Footer from 'components/footer';
import { MessageCircleQuestion } from 'lucide-react';
import type { ReactNode } from 'react';
import { Toaster } from 'components/ui/sonner';
import { cn } from 'utils/shadcn';
import { useChatOpen } from 'components/chat/chat-open-context';
import { useEffect } from 'react';
import { useLiveHitsOpen } from 'components/chat/live-hits-open-context';
import { useMediaQuery } from 'react-responsive';

export default function LayoutController({
  children,
  header,
  liveHits,
  awaitingCryptoConfirmations,
  chat,
}: {
  children: ReactNode;
  header: ReactNode;
  liveHits: ReactNode;
  awaitingCryptoConfirmations: ReactNode;
  chat: ReactNode;
}) {
  const { isOpen: isChatOpen, setOpen: setIsChatOpen } = useChatOpen();
  const { isOpen: isLiveHitsOpen, setOpen: setIsLiveHitsOpen } =
    useLiveHitsOpen();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (isMobile) {
      if (isChatOpen) {
        setIsLiveHitsOpen(false);
      } else if (isLiveHitsOpen) {
        setIsChatOpen(false);
      }
    }
  }, [isChatOpen, isLiveHitsOpen, isMobile, setIsLiveHitsOpen, setIsChatOpen]);

  const liveHitsWidth = '14rem';
  const chatWidth = '17.5rem';

  return (
    <div>
      <div className="pointer-events-none fixed inset-0 z-10 flex h-[100dvh] flex-col items-stretch">
        {header}

        <div className="relative w-full flex-grow">
          {/* live hits */}
          <CSSTransition
            in={isLiveHitsOpen}
            timeout={200}
            classNames="slide-right"
            unmountOnExit
          >
            <div className="pointer-events-auto absolute isolate z-10 flex h-full">
              <section
                className="relative z-10 flex h-full flex-col items-stretch overflow-hidden bg-primary-foreground"
                style={{ width: liveHitsWidth }}
              >
                {liveHits}
              </section>
            </div>
          </CSSTransition>

          {/* live hits toggle button */}
          <button
            className={`pointer-events-auto fixed bottom-4 z-20 flex h-10 items-center rounded-br-sm rounded-tr-sm bg-primary-foreground transition-transform duration-200 ease-out`}
            style={{
              transform: isLiveHitsOpen
                ? `translateX(${liveHitsWidth})`
                : isMobile && isChatOpen
                  ? 'translateX(-2.5rem)'
                  : 'translateX(0)',
              transitionDuration: '200ms',
              transitionTimingFunction: 'ease-out',
            }}
            onClick={() => setIsLiveHitsOpen(!isLiveHitsOpen)}
          >
            <div className="flex h-full w-10 items-center justify-center bg-transparent">
              {isLiveHitsOpen ? <ArrowLeftIcon /> : <ArrowRightIcon />}
            </div>
          </button>

          {/* layout */}
          <div className="pointer-events-none absolute inset-0 flex h-full w-full">
            <div
              className="h-full shrink-0 grow-0 transition-all duration-200 ease-out 3xl:block"
              style={{ width: isLiveHitsOpen ? liveHitsWidth : '0' }}
            />
            <div className="relative flex-grow p-2">
              {awaitingCryptoConfirmations}
            </div>
            <div
              className="h-full shrink-0 grow-0 transition-all duration-200 ease-out 3xl:block"
              style={{ width: isChatOpen ? chatWidth : '0' }}
            />
          </div>

          {/* chat */}
          <div
            className={cn(
              `pointer-events-auto absolute right-0 isolate z-10 flex h-full transition-transform duration-200 ease-out`,
              {
                'translate-x-full': !isChatOpen,
                'translate-x-0': isChatOpen,
              },
            )}
            style={{
              transitionDuration: '200ms',
              transitionTimingFunction: 'ease-out',
            }}
          >
            <section
              className="relative z-40 flex h-full flex-col overflow-hidden bg-primary-foreground"
              style={{ width: chatWidth }}
            >
              {chat}
            </section>
          </div>

          {/* chat toggle button */}
          <button
            className={`pointer-events-auto fixed bottom-4 z-20 flex h-10 items-center rounded-bl-sm rounded-tl-sm bg-primary-foreground transition-transform duration-200 ease-out`}
            style={{
              right: 0,
              transform: isChatOpen
                ? `translateX(-${chatWidth})`
                : isMobile && isLiveHitsOpen
                  ? 'translateX(2.5rem)'
                  : 'translateX(0)',
              transitionDuration: '200ms',
              transitionTimingFunction: 'ease-out',
            }}
            onClick={() => setIsChatOpen(!isChatOpen)}
          >
            <div className="flex h-full w-10 items-center justify-center bg-transparent">
              <ChatIcon />
            </div>
          </button>

          <button
            className={`custom-intercom-launcher pointer-events-auto fixed bottom-16 z-20 flex h-10 items-center rounded-r-sm bg-secondary-foreground transition-transform duration-200 ease-out`}
            style={{
              transform: isLiveHitsOpen
                ? `translateX(${liveHitsWidth})`
                : 'translateX(0)',
              transitionDuration: '200ms',
              transitionTimingFunction: 'ease-out',
            }}
          >
            <div className="flex h-full w-10 items-center justify-center bg-transparent">
              <MessageCircleQuestion className="size-6 text-secondary" />
            </div>
          </button>
        </div>
      </div>

      {/* content */}
      <div className="mt-16 flex w-full max-w-full">
        <div
          className="hidden h-full shrink-0 grow-0 transition-all duration-200 ease-out 3xl:block"
          style={{ width: isLiveHitsOpen ? liveHitsWidth : '0' }}
        />
        <div className="min-w-0 flex-grow">
          <main
            className="relative isolate mx-auto min-h-screen max-w-7xl p-4 sm:p-6 3xl:p-10"
            style={{ containerType: 'inline-size' }}
          >
            {children}
          </main>
          <footer className="relative w-full max-w-full bg-background p-4">
            <Footer />
          </footer>
        </div>
        <div
          className="hidden h-full shrink-0 grow-0 transition-all duration-200 ease-out 3xl:block"
          style={{ width: isChatOpen ? chatWidth : '0' }}
        />
      </div>

      <Toaster
        className="z-[999999999999]"
        closeButton
        visibleToasts={4}
        icons={{
          success: '✅',
          error: '❌',
        }}
      />
    </div>
  );
}
