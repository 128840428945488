import React, { useEffect, useRef, useState } from 'react';
import { animated, easings, useSpring } from '@react-spring/web';

const SYMBOLS = ['?', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function AnimatedNumberWithSpring({
  value,
  duration,
}: {
  value: number;
  duration?: number;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [springs, api] = useSpring(() => {
    const span = containerRef.current?.querySelector<HTMLSpanElement>(
      `span:nth-child(${value + 3})`,
    );

    return {
      from: {
        y: span?.offsetTop !== undefined ? -span.offsetTop : 0,
      },
    };
  });

  const [prevValue, setPrevValue] = useState<number>(value);

  const charOffset = containerRef.current?.querySelector<HTMLSpanElement>(
    `span:nth-child(${value + 3})`,
  )?.offsetTop;

  useEffect(() => {
    if (prevValue !== value && charOffset !== undefined) {
      setPrevValue(value);

      void api.start({
        to: [
          {
            y: -charOffset,
            config: {
              easing: easings.easeOutBack,
              duration: duration ?? 1_000,
            },
          },
        ],
      });
    }
  }, [api, charOffset, duration, prevValue, value]);

  return (
    <div className="relative" ref={containerRef}>
      <animated.div className="absolute flex flex-col" style={springs}>
        <span className="text-center">{value}</span>
        {SYMBOLS.map((number) => (
          <span key={number} className="text-center">
            {number}
          </span>
        ))}
      </animated.div>

      <span className="opacity-0">0</span>
    </div>
  );
}
