'use client';

import { Box, Swords } from 'lucide-react';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import Coin from 'components/coin';
import type { Database } from 'schema.gen';
import { Flip } from 'gsap/Flip';
import Image from 'next/image';
import Link from 'next/link';
import Typography from 'components/ui/typography';
import { createClient } from 'utils/supabase/client';
import { gsap } from 'gsap';
import { useListState } from '@mantine/hooks';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';

type LiveHit = Database['public']['Tables']['live_hits']['Row'];

// Register the Flip plugin
gsap.registerPlugin(Flip);

export default function RealtimeLiveHits({
  serverLiveHits,
}: {
  serverLiveHits: LiveHit[];
}) {
  const [liveHits, { prepend, setState }] = useListState(serverLiveHits);
  const [pendingLiveHits, { append, shift }] = useListState<LiveHit>();
  const [isAnimating, setIsAnimating] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const firstLiveHitId = liveHits[0]?.id;
  const [prevInitialLiveHitId, setPrevInitialLiveHitId] = useState<
    string | undefined
  >(firstLiveHitId);

  useLayoutEffect(() => {
    if (containerRef.current) {
      if (firstLiveHitId !== prevInitialLiveHitId) {
        setPrevInitialLiveHitId(firstLiveHitId);
        setIsAnimating(true);
        const container = containerRef.current;
        const items = gsap.utils.toArray<HTMLElement>(container.children);
        const newItem = items[0];
        if (newItem === undefined) {
          return;
        }
        gsap.set(container, {
          y: -newItem.offsetHeight,
        });
        const state = Flip.getState(container);
        gsap
          .timeline()
          .add(
            Flip.from(state, {
              ease: 'power1.inOut',
              duration: 0.5,
            }),
          )
          .to(
            container,
            {
              y: 0,
              opacity: 1,
              duration: 0.5,
              ease: 'power1.inOut',
              onComplete: () => {
                setIsAnimating(false);
              },
            },
            0,
          );
      }
    }
  }, [firstLiveHitId, prevInitialLiveHitId]);

  useEffect(() => {
    if (pendingLiveHits.length > 0 && !isAnimating) {
      setIsAnimating(true);
      const [nextUp] = pendingLiveHits;
      shift();
      setState(liveHits.slice(0, -1));
      prepend(nextUp!);
    }
  }, [pendingLiveHits, isAnimating, shift, prepend, setState, liveHits]);

  const createLiveHitsChannel = useCallback(() => {
    const supabase = createClient();

    return supabase.channel('realtime_live_hits').on<LiveHit>(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'live_hits',
      },
      (payload) => {
        append(payload.new);
      },
    );
  }, [append]);

  const fetchLatestLiveHits = useCallback(async () => {
    const supabase = createClient();
    const { data } = await supabase
      .from('live_hits')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(30);

    if (data) {
      setState(data);
    }
  }, [setState]);

  useReconnectOnTabChange(createLiveHitsChannel, fetchLatestLiveHits);

  return (
    <div className="relative h-full overflow-y-scroll">
      <div ref={containerRef} className="absolute flex w-full flex-col">
        {liveHits.map((hit) => (
          <LiveHitCard key={hit.id} hit={hit} />
        ))}
      </div>
    </div>
  );
}

function LiveHitCard({ hit }: { hit: LiveHit }) {
  return (
    <Link
      href={hit.link_path}
      className="relative flex items-center gap-2 overflow-hidden p-2 transition-colors hover:bg-opacity-75"
      style={{
        background: 'transparent',
        borderLeft: `4px solid ${hit.color}`,
      }}
    >
      <div className="shrink-0">
        {hit.source === 'box' ? <Box /> : <Swords />}
      </div>
      <div className="relative z-10 h-12 w-12 flex-shrink-0">
        <Image
          src={hit.image}
          alt={hit.name}
          fill
          className="rounded-md object-cover"
          sizes="48px"
        />
      </div>
      <div className="z-10 flex flex-col gap-1 overflow-hidden">
        <Typography
          variant="p"
          affects="xs"
          className="truncate font-semibold text-primary"
        >
          {hit.name}
        </Typography>
        <Typography
          variant="p"
          affects="xs"
          className="flex items-center font-medium leading-none text-primary"
        >
          <Coin className="mr-1 inline size-3" /> {hit.display_value}
        </Typography>
        <Typography variant="p" affects="xs" className="text-muted-foreground">
          {hit.username}
        </Typography>
      </div>
      <div
        className={`absolute inset-0 z-0 bg-gradient-to-br from-transparent to-background`}
      ></div>
    </Link>
  );
}

export function LiveHitsSkeleton() {
  return (
    <div>
      {[...Array(15)].map((_, i) => (
        <div
          key={i}
          className="flex items-center gap-2 overflow-hidden p-2"
          style={{
            borderLeft: '4px solid #e2e8f0',
          }}
        >
          <div className="bg-frost relative z-10 h-12 w-12 flex-shrink-0 animate-pulse rounded-md"></div>
          <div className="z-10 flex flex-grow flex-col overflow-hidden">
            <div className="bg-frost mb-1 h-4 w-3/4 animate-pulse rounded"></div>
            <div className="bg-frost mb-1 h-3 w-1/2 animate-pulse rounded"></div>
            <div className="bg-frost h-3 w-1/3 animate-pulse rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );
}
