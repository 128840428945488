import { useCallback, useEffect, useState } from 'react';

import type { RealtimeChannel } from '@supabase/supabase-js';

export default function useReconnectOnTabChange(
  createChannel: () => RealtimeChannel | Promise<RealtimeChannel>,
  onReconnect?: () => Promise<void>,
) {
  const [channel, setChannel] = useState<RealtimeChannel | null>(null);
  const [isInitialRender, setInitialRender] = useState(true);
  const [documentHidden, setDocumentHidden] = useState(false);
  const [status, setStatus] = useState<
    'SUBSCRIBED' | 'TIMED_OUT' | 'CLOSED' | 'CHANNEL_ERROR' | null
  >(null);
  const [retries, setRetries] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);

  const disconnect = useCallback(async () => {
    if (channel !== null) {
      await channel.unsubscribe();
      setChannel(null);
      setStatus(null);
    }
  }, [channel]);

  const connect = useCallback(async () => {
    if (channel === null) {
      const newChannel = await createChannel();
      newChannel.subscribe((status) => {
        setStatus(status);
      });
      setChannel(newChannel);
    }
  }, [channel, createChannel]);

  const onTabChange = useCallback(() => {
    setDocumentHidden(document.hidden);
  }, []);

  useEffect(() => {
    if (
      !documentHidden &&
      status !== 'SUBSCRIBED' &&
      retries < 5 &&
      !isRetrying
    ) {
      setIsRetrying(true);

      void Promise.resolve()
        .then(async () => {
          try {
            await disconnect();
            await connect();
            return onReconnect?.();
          } finally {
            setRetries(retries + 1);
          }
        })
        .finally(() => {
          setIsRetrying(false);
        });
    }
  }, [
    connect,
    disconnect,
    documentHidden,
    isRetrying,
    onReconnect,
    retries,
    status,
  ]);

  useEffect(() => {
    if (status === 'SUBSCRIBED') {
      setRetries(0);
    }
  }, [channel, status]);

  useEffect(() => {
    if (isInitialRender) {
      void connect();
      setInitialRender(false);
    }
  }, [connect, isInitialRender]);

  // reconnect to channel on tab change
  useEffect(() => {
    document.addEventListener('visibilitychange', onTabChange);

    return () => {
      document.removeEventListener('visibilitychange', onTabChange);
    };
  }, [connect, onTabChange]);

  return { status, channel };
}
