'use client';

import { Button, type ButtonProps } from 'components/ui/button';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useFormState, useFormStatus } from 'react-dom';

import AnimatedNumbers from 'components/animated-numbers';
import { EMPTY_FORM_STATE } from 'utils/forms';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { createClient } from 'utils/supabase/client';
import { env } from 'env';
import formatCoins from 'utils/format/coins';
import { joinCoinDrop } from 'actions/coin-drop';
import { toast } from 'components/ui/sonner';
import { useFormToastMessage } from 'hooks/use-form-toast-message';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';
import { cn } from 'utils/shadcn';
import Typography from 'components/ui/typography';
import Coin from 'components/coin';
import { CheckIcon, Cross1Icon } from '@radix-ui/react-icons';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import Divider from 'components/ui/divider';
import { Progress } from 'components/ui/progress';
import InfoIcon from 'icons/info';
import { useChatOpen } from './chat-open-context';

export default function RealtimeCoinDrop({
  userId,
  serverValue,
  serverIsJoined,
  serverJoinableAt,
  serverStartsAt,
  serverEndsAt,
  serverTimeRemainingPercentage,
}: {
  userId?: string;
  serverValue: number;
  serverIsJoined: boolean;
  serverJoinableAt: string;
  serverStartsAt: string;
  serverEndsAt: string;
  serverTimeRemainingPercentage: number;
}) {
  const [value, setValue] = useState(serverValue);
  const [isJoined, setIsJoined] = useState(serverIsJoined);
  const [joinableAt, setJoinableAt] = useState(serverJoinableAt);
  const [startsAt, setStartsAt] = useState(serverStartsAt);
  const [endsAt, setEndsAt] = useState(serverEndsAt);
  const [isDismissed, setIsDismissed] = useState(false);

  const [timeRemainingPercentage, setTimeRemainingPercentage] = useState(
    serverTimeRemainingPercentage,
  );

  const { isOpen } = useChatOpen();

  useEffect(() => {
    const updateTimeRemaining = () => {
      const now = new Date();
      const start = new Date(startsAt);
      const end = new Date(endsAt);

      if (now < start) {
        setTimeRemainingPercentage(100);
      } else if (now > end) {
        setTimeRemainingPercentage(0);
      } else {
        const totalDuration = end.getTime() - start.getTime();
        const elapsed = now.getTime() - start.getTime();
        const remaining = 100 - (elapsed / totalDuration) * 100;
        setTimeRemainingPercentage(remaining);
      }
    };

    updateTimeRemaining();
    const interval = setInterval(updateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [startsAt, endsAt]);

  const fetchLatestCoinDrop = useCallback(async () => {
    const supabase = createClient();

    const { data, error } = await supabase
      .from('coin_drops')
      .select('*,coin_drop_entries(*)')
      .eq('status', 'active')
      .filter(
        'coin_drop_entries.user_id',
        'eq',
        userId ?? 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa',
      )
      .returns<
        {
          coin_drop_entries: {
            user_id: string;
          }[];
          value: number;
          joinable_at: string;
          starts_at: string;
          ends_at: string;
        }[]
      >()
      .single();

    if (error !== null) {
      toast.error('Failed to fetch active coin drop');
      return;
    }

    setValue(data.value);

    setIsJoined(
      data.coin_drop_entries.some((entry) => entry.user_id === userId),
    );
    setJoinableAt(data.joinable_at);
    setStartsAt(data.starts_at);
    setEndsAt(data.ends_at);
  }, [userId]);

  const createCoinDropChannel = useCallback(() => {
    const supabase = createClient();

    return supabase.channel('realtime_coin_drop').on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'coin_drops',
      },
      (payload) => {
        if (payload.new.status === 'completed') {
          void fetchLatestCoinDrop();
          setIsDismissed(false);
        } else {
          setValue(payload.new.value);
        }
      },
    );
  }, [fetchLatestCoinDrop]);

  useReconnectOnTabChange(createCoinDropChannel, fetchLatestCoinDrop);

  const isEnded = new Date(endsAt) < new Date();
  const isJoinable = new Date(joinableAt) < new Date() && !isEnded;

  const isVisibleOnMain =
    !isOpen && !isJoined && isJoinable && !isDismissed && value > 0;

  return (
    <div
      className={cn(
        `absolute left-1/2 top-0 z-10 w-[calc(100%-1rem)] overflow-hidden rounded-lg bg-secondary p-4 transition-all -translate-x-1/2 translate-y-0`,
        {
          '-translate-y-full': isEnded,
          'left-0 -translate-x-full': isVisibleOnMain,
        },
      )}
    >
      <div className="mb-2 flex items-center gap-2">
        {isJoinable ? (
          <div>
            <Typography
              variant="p"
              className="grow text-sm font-semibold uppercase tracking-tight"
            >
              <span className="mr-1">Coin Drop Incoming</span>
              <CoinDropInfoPopover />
            </Typography>
            <Typography
              variant="p"
              className="text-xs font-normal tracking-tight text-secondary-foreground/80"
            >
              Join now to collect a share of the coins
            </Typography>
          </div>
        ) : (
          <Typography
            variant="p"
            className="grow text-sm font-semibold uppercase tracking-tight"
          >
            <span className="mr-1">Coin Drop</span>
            <CoinDropInfoPopover />
          </Typography>
        )}

        <div
          className={cn(
            'flex shrink-0 items-center gap-1 rounded-full py-0.5 pl-1 pr-1.5 text-sm transition-colors',
            {
              'bg-yellow-500/20': isJoinable,
              'bg-frost': !isJoinable,
            },
          )}
        >
          <Coin className="size-4" />
          <AnimatedNumbers value={formatCoins(value)} />
        </div>

        {isVisibleOnMain && (
          <button
            onClick={() => setIsDismissed(true)}
            className="absolute right-2 top-2"
          >
            <Cross1Icon />
          </button>
        )}
      </div>
      {isJoinable && !isJoined ? (
        <JoinCoinDropForm onJoin={() => setIsJoined(true)} />
      ) : isJoined ? (
        <Typography
          variant="span"
          affects="xs"
          className="flex w-fit items-center gap-1 rounded-full bg-black/30 p-1 pr-3"
        >
          <CheckIcon className="size-5 text-success" /> Joined!
        </Typography>
      ) : null}
      <div className="mt-4">
        <Progress value={timeRemainingPercentage} className="w-full" />
      </div>

      <div
        className={`absolute -left-full top-0 -z-10 size-[200%] rounded-full bg-rich-gold blur-2xl transition-opacity duration-300 ${
          isJoinable ? 'opacity-100' : 'opacity-0'
        }`}
        style={{
          pointerEvents: 'none',
          zIndex: -10,
        }}
      />
    </div>
  );
}

function CoinDropInfoPopover() {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <button>
          <InfoIcon className="size-3" />
        </button>
      </PopoverTrigger>

      <PopoverContent className="space-y-2" side="left">
        <Typography variant="p" className="text-lg font-bold">
          What is Coin Drop?
        </Typography>
        <Divider variant="section" />
        <Typography variant="p" affects="muted">
          Coin Drop is a unique, free-to-play rewards feature. During the last 5
          minutes of every 30 minute drop, click the Join button to receive a
          share of the coin pool.
        </Typography>
      </PopoverContent>
    </Popover>
  );
}

function JoinCoinDropForm({ onJoin }: { onJoin: () => void }) {
  const [formState, action] = useFormState(joinCoinDrop, EMPTY_FORM_STATE);
  const captcha = useRef<HCaptcha>(null);
  const [token, setToken] = useState<string>();
  const formRef = useRef<HTMLFormElement>(null);

  const prevTimestamp = useRef(formState.timestamp);
  useFormToastMessage(formState);

  if (prevTimestamp.current !== formState.timestamp) {
    prevTimestamp.current = formState.timestamp;

    if (formState.status === 'SUCCESS') {
      onJoin();
    } else if (formState.status === 'ERROR') {
      toast.error(formState.message);
    }

    captcha.current?.resetCaptcha();
  }

  useEffect(() => {
    if (token) {
      formRef.current?.requestSubmit();
    }
  }, [token]);

  return (
    <form action={action} ref={formRef}>
      <CoinDropSubmitButton
        size="sm"
        onClick={(event) => {
          if (!token && env.NEXT_PUBLIC_CAPTCHA_ENABLED) {
            event.preventDefault();
            captcha.current?.execute();
          }
        }}
      />

      <input type="hidden" name="hcaptcha_token" value={token ?? ''} />

      {env.NEXT_PUBLIC_CAPTCHA_ENABLED && (
        <HCaptcha
          ref={captcha}
          sitekey={env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY}
          onVerify={setToken}
          onExpire={() => {
            captcha.current?.resetCaptcha();
          }}
          onChalExpired={() => {
            captcha.current?.resetCaptcha();
          }}
          theme="dark"
          loadAsync
          size="invisible"
        />
      )}
    </form>
  );
}

function CoinDropSubmitButton({ disabled, ...props }: ButtonProps) {
  const { pending } = useFormStatus();

  return (
    <Button
      variant="action"
      type="submit"
      disabled={pending || disabled}
      isProcessing={pending}
      {...props}
    >
      Join
    </Button>
  );
}
